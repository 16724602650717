// src/components/LandingPage.js
import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faStar, faHeart, faSmile, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// Import czcionki Quicksand
import '@fontsource/quicksand/300.css';
import '@fontsource/quicksand/400.css';
import '@fontsource/quicksand/500.css';
import '@fontsource/quicksand/700.css';

// Inicjalizacja biblioteki FontAwesome
library.add(faBook, faStar, faHeart, faSmile, faChevronRight);

function LandingPage({ onStartLearning }) {
  const styles = {
    container: {
      minHeight: '100vh',
      background: 'linear-gradient(to bottom, #FEF7CD, #FFDEE2, #D3E4FD)',
      color: '#2A2A2A',
      fontFamily: "'Quicksand', sans-serif",
    },
    section: {
      padding: '40px 20px',
      maxWidth: '1200px',
      margin: '0 auto',
      textAlign: 'center',
    },
    hero: {
      paddingTop: '60px',
      paddingBottom: '60px',
      position: 'relative',
    },
    title: {
      fontSize: '3.5rem',
      fontWeight: '700',
      marginBottom: '20px',
      color: '#2A2A2A',
      textAlign: 'center',
      textShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    subtitle: {
      fontSize: '1.3rem',
      color: '#444444',
      maxWidth: '600px',
      margin: '0 auto 40px',
      lineHeight: '1.6',
      textAlign: 'center',
      fontWeight: '500',
    },
    button: {
      background: 'linear-gradient(45deg, #FF9A9E, #67B26F)',
      color: 'white',
      padding: '15px 35px',
      borderRadius: '50px',
      border: 'none',
      fontSize: '1.2rem',
      fontWeight: '600',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '10px',
      transition: 'transform 0.3s, box-shadow 0.3s',
      boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
    },
    buttonHover: {
      transform: 'translateY(-2px)',
      boxShadow: '0 6px 20px rgba(0,0,0,0.15)',
    },
    featuresTitle: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      marginBottom: '50px',
      color: '#2A2A2A',
    },
    featuresGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '30px',
      padding: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    featureCard: {
      background: 'rgba(255, 255, 255, 0.95)',
      padding: '30px',
      borderRadius: '20px',
      textAlign: 'center',
      boxShadow: '0 4px 15px rgba(0,0,0,0.08)',
      transition: 'transform 0.3s',
    },
    featureIcon: {
      fontSize: '2.5rem',
      color: '#FF9A9E',
      marginBottom: '20px',
    },
    featureTitle: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      marginBottom: '15px',
      color: '#2A2A2A',
    },
    featureDescription: {
      color: '#444444',
      lineHeight: '1.6',
      fontSize: '1.1rem',
      fontWeight: '500',
    },
    ctaSection: {
      padding: '80px 20px',
      textAlign: 'center',
      position: 'relative',
    },
  };

  const features = [
    {
      title: "Interaktywna nauka",
      description: "Wciągające ćwiczenia, które uczą poprzez zabawę",
      icon: faBook,
    },
    {
      title: "Personalizacja",
      description: "Dostosuj wygląd i poziom trudności do potrzeb dziecka",
      icon: faHeart,
    },
    {
      title: "Śledzenie postępów",
      description: "Monitoruj rozwój dziecka w czasie rzeczywistym",
      icon: faStar,
    },
    {
      title: "Przyjazne środowisko",
      description: "Bezpieczna i przyjazna przestrzeń do nauki",
      icon: faSmile,
    },
  ];

  return (
    <div style={styles.container}>
      {/* Hero Section */}
      <section style={{...styles.section, ...styles.hero}}>
        <h1 style={styles.title}>Literki i Sylabki</h1>
        <p style={styles.subtitle}>
          Odkryj radość z nauki czytania z Literkami i Sylabkami! 
          Twoje dziecko pokocha przyjazne lekcje, które zamieniają naukę w zabawę.
        </p>
        <button 
          style={styles.button}
          onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
          onMouseLeave={(e) => Object.assign(e.target.style, {transform: 'none', boxShadow: styles.button.boxShadow})}
          onClick={onStartLearning}
        >
          Rozpocznij przygodę
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </section>

      {/* Features Section */}
      <section style={styles.section}>
        <h2 style={styles.featuresTitle}>
          Dlaczego warto wybrać <span>Literki i Sylabki</span>?
        </h2>
        <div style={styles.featuresGrid}>
          {features.map((feature, index) => (
            <div 
              key={index} 
              style={styles.featureCard}
              onMouseEnter={(e) => e.target.style.transform = 'translateY(-10px)'}
              onMouseLeave={(e) => e.target.style.transform = 'none'}
            >
              <FontAwesomeIcon icon={feature.icon} style={styles.featureIcon} />
              <h3 style={styles.featureTitle}>{feature.title}</h3>
              <p style={styles.featureDescription}>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section style={styles.ctaSection}>
        <h2 style={{...styles.title, marginBottom: '30px'}}>Rozpocznij naukę już dziś!</h2>
        <p style={styles.subtitle}>
          Dołącz do tysięcy zadowolonych rodziców i dzieci, które już odkryły 
          radość nauki z Literkami i Sylabkami.
        </p>
        <button 
          style={styles.button}
          onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
          onMouseLeave={(e) => Object.assign(e.target.style, {transform: 'none', boxShadow: styles.button.boxShadow})}
          onClick={onStartLearning}
        >
          Wypróbuj za darmo
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </section>
    </div>
  );
}

export default LandingPage;
