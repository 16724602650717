// src/components/HistoryAndStats.js
import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import { Eye, ThumbsUp, X, ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'lucide-react';
import { getThemeColors } from '../styles/themes';
import { motion } from 'framer-motion';
import { Circle, Check } from 'lucide-react';

const alphabet = 'AĄBCĆDEĘFGHIJKLŁMNŃOÓPRSŚTUWXYZŹŻaąbcćdeęfghijklłmnńoóprsśtuwxyzźż';

const HistoryAndStats = ({ currentUser, currentProfile, settings, currentExerciseType }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [stats, setStats] = useState(null);

  // Ustalmy tryb ćwiczenia
  const exerciseType = settings.learningMode === 'sylaby' ? 'syllables' : 'letters';

  // Zakresy czasu
  const [sliderValue, setSliderValue] = useState(4);
  const timeRanges = ['1d', '7d', '30d', '1y', 'all'];
  const [timeRange, setTimeRange] = useState('all');

  const [showStats, setShowStats] = useState(true);
  const [showHistory, setShowHistory] = useState(true);

  const [sortColumn, setSortColumn] = useState('character');
  const [sortDirection, setSortDirection] = useState('asc');

  const colorMap = {
    '#000000': 'czarny',
    '#FF0000': 'czerwony',
    '#0000FF': 'niebieski',
    '#008000': 'zielony',
    '#FFFF00': 'żółty',
  };

  const sizeMap = {
    50: 'S',
    100: 'M',
    150: 'L',
    200: 'XL',
  };

  const themeColors = getThemeColors(settings?.theme || 'ksiezniczkowy');

  const styles = {
    panel: {
      position: 'fixed',
      top: 0,
      right: isPanelOpen ? '0' : '-350px',
      width: '350px',
      height: '100%',
      background: `linear-gradient(135deg, ${themeColors.panel} 0%, ${themeColors.panel}ee 100%)`,
      boxShadow: '-2px 0 20px rgba(0,0,0,0.15)',
      transition: 'right 0.3s ease-in-out, background-color 0.3s',
      overflowY: 'auto',
      zIndex: 1000,
      padding: '20px',
    },
    toggleButton: {
      position: 'fixed',
      top: '60px',
      right: isPanelOpen ? '355px' : '0px',
      width: '45px',
      height: '30px',
      backgroundColor: themeColors.button,
      border: 'none',
      borderRadius: '4px 0 0 4px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'right 0.3s ease-in-out',
      zIndex: 1001,
      boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
    },
    historyContent: {
      marginTop: '10px',
    },
    historyList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    historyItem: {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: '12px',
      marginBottom: '10px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    },
    historyItemCorrect: {
      backgroundColor: 'rgba(230, 255, 237, 0.95)',
    },
    historyItemIncorrect: {
      backgroundColor: 'rgba(255, 230, 230, 0.95)',
    },
    historyItemSpan: {
      marginBottom: '5px',
    },
    statsContent: {
      marginTop: '10px',
      paddingBottom: '20px',
    },
    statsSummary: {
      backgroundColor: '#FFFFFF',
      padding: '15px',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      marginBottom: '20px',
    },
    statsTable: {
      width: '95%',
      margin: '0 auto',
      borderCollapse: 'collapse',
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      marginBottom: '20px',
    },
    statsTableHeader: {
      backgroundColor: '#f2f2f2',
      fontWeight: 'bold',
      padding: '10px 6px',
      borderBottom: '2px solid #ddd',
      fontSize: '12px',
      cursor: 'pointer'
    },
    statsTableCell: {
      padding: '6px',
      borderBottom: '1px solid #ddd',
      textAlign: 'center',
      fontSize: '11px',
    },
    statsTableCellBold: {
      fontWeight: 'bold',
    },
    timeRangeSlider: {
      margin: '20px 0',
      backgroundColor: '#FFFFFF',
      padding: '15px',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    sliderInput: {
      WebkitAppearance: 'none',
      width: '100%',
      height: '8px',
      backgroundColor: '#ddd',
      borderRadius: '5px',
      outline: 'none',
      margin: '10px 0',
    },
    sliderLabels: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.9em',
      marginTop: '5px',
    },
    section: {
      marginBottom: '20px',
      padding: '15px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 0',
      marginBottom: '15px',
      borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    if (!currentUser?.uid || !currentProfile?.id) {
      setStats(null);
      return;
    }

    let unsubscribe;
    try {
      const responsesRef = collection(db, 'users', currentUser.uid, 'profiles', currentProfile.id, 'responses');
      unsubscribe = onSnapshot(responsesRef, () => {
        fetchStats().catch(console.error);
      });
    } catch (error) {
      console.error('Błąd podczas konfiguracji nasłuchiwania stats:', error);
      setStats(null);
    }

    return () => {
      if (unsubscribe) {
        try {
          unsubscribe();
        } catch (error) {
          console.error('Błąd podczas czyszczenia subskrypcji stats:', error);
        }
      }
    };
  }, [currentUser, currentProfile, exerciseType, timeRange]);

  useEffect(() => {
    if (!currentUser?.uid || !currentProfile?.id) {
      setHistory([]);
      return;
    }

    let unsubscribe;
    try {
      const responsesRef = collection(db, 'users', currentUser.uid, 'profiles', currentProfile.id, 'responses');
      const q = query(responsesRef, orderBy('timestamp', 'desc'), limit(50));

      unsubscribe = onSnapshot(q, (snapshot) => {
        const historyData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHistory(historyData);
      }, (error) => {
        console.error('HistoryAndStats: Błąd podczas pobierania historii:', error);
        setHistory([]);
      });
    } catch (error) {
      console.error('Błąd podczas konfiguracji nasłuchiwania:', error);
      setHistory([]);
    }

    return () => {
      if (unsubscribe) {
        try {
          unsubscribe();
        } catch (error) {
          console.error('Błąd podczas czyszczenia subskrypcji:', error);
        }
      }
    };
  }, [currentUser, currentProfile]);

  const fetchStats = async () => {
    if (!currentUser?.uid || !currentProfile?.id) {
      setStats(null);
      return;
    }

    try {
      const responsesRef = collection(db, 'users', currentUser.uid, 'profiles', currentProfile.id, 'responses');
      const snapshot = await getDocs(responsesRef);
      let responses = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));

      // Filtrowanie wg czasu
      if (timeRange !== 'all') {
        const now = new Date();
        let startDate;
        switch (timeRange) {
          case '1d': startDate = new Date(now - 24 * 60 * 60 * 1000); break;
          case '7d': startDate = new Date(now - 7 * 24 * 60 * 60 * 1000); break;
          case '30d': startDate = new Date(now - 30 * 24 * 60 * 60 * 1000); break;
          case '1y': startDate = new Date(now - 365 * 24 * 60 * 60 * 1000); break;
          default: startDate = null;
        }
        if (startDate) {
          responses = responses.filter(res => res.timestamp?.toDate() >= startDate);
        }
      }

      // Filtrowanie wg trybu (litery/sylaby/liczby)
      const currentMode = settings.learningMode;
      responses = responses.filter(response => {
        return response.settings?.learningMode === currentMode;
      });

      const totalAttempts = responses.length;
      const correctResponses = responses.filter(res => res.isCorrect).length;
      const correctPercentage = totalAttempts > 0 ? Math.round((correctResponses / totalAttempts) * 100) : 0;

      const characterStats = {};
      responses.forEach(res => {
        if (!res.character) return;
        const char = res.character;
        if (!characterStats[char]) {
          characterStats[char] = {
            character: char,
            total: 0,
            correctTotal: 0
          };
        }

        characterStats[char].total++;
        if (res.isCorrect) {
          characterStats[char].correctTotal++;
        }
      });

      const characterStatsArray = Object.values(characterStats)
        .sort((a, b) => a.character.localeCompare(b.character, 'pl'));

      setStats({
        totalAttempts,
        correctResponses,
        correctPercentage,
        characterStats: characterStatsArray,
      });

    } catch (error) {
      console.error('HistoryAndStats: Błąd podczas pobierania statystyk:', error);
      setStats(null);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return '';
    const date = timestamp.toDate();
    return date.toLocaleString('pl-PL');
  };

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSliderValue(value);
    setTimeRange(timeRanges[value]);
  };

  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortDirection === 'asc' ? '▲' : '▼';
    }
    return null;
  };

  const handleSort = (column) => {
    const newDirection = column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  const sortData = (data, column, direction) => {
    return [...data].sort((a, b) => {
      switch(column) {
        case 'character':
          if (settings.learningMode === 'liczby') {
            // Konwertujemy stringi na liczby dla prawidłowego sortowania
            const numA = parseInt(a.character, 10);
            const numB = parseInt(b.character, 10);
            return direction === 'asc' ? numA - numB : numB - numA;
          }
          return direction === 'asc' 
            ? a.character.localeCompare(b.character, 'pl') 
            : b.character.localeCompare(a.character, 'pl');
        case 'upperViews':
          if (exerciseType === 'syllables' || settings.learningMode === 'liczby') {
            return direction === 'asc'
              ? a.total - b.total
              : b.total - a.total;
          } else {
            return direction === 'asc'
              ? a.upperTotal - b.upperTotal
              : b.upperTotal - a.upperTotal;
          }
        case 'upperCorrect':
          if (exerciseType === 'syllables' || settings.learningMode === 'liczby') {
            const percentA = a.total > 0 ? (a.correctTotal / a.total) * 100 : 0;
            const percentB = b.total > 0 ? (b.correctTotal / b.total) * 100 : 0;
            return direction === 'asc'
              ? percentA - percentB
              : percentB - percentA;
          } else {
            const percentA = a.upperTotal > 0 ? (a.upperCorrect / a.upperTotal) * 100 : 0;
            const percentB = b.upperTotal > 0 ? (b.upperCorrect / b.upperTotal) * 100 : 0;
            return direction === 'asc'
              ? percentA - percentB
              : percentB - percentA;
          }
        case 'lowerViews':
          return direction === 'asc'
            ? a.lowerTotal - b.lowerTotal
            : b.lowerTotal - a.lowerTotal;
        case 'lowerCorrect':
          const lowerPercentA = a.lowerTotal > 0 ? (a.lowerCorrect / a.lowerTotal) * 100 : 0;
          const lowerPercentB = b.lowerTotal > 0 ? (b.lowerCorrect / b.lowerTotal) * 100 : 0;
          return direction === 'asc'
            ? lowerPercentA - lowerPercentB
            : lowerPercentB - lowerPercentA;
        default:
          return 0;
      }
    });
  };

  const renderStatsTable = () => {
    if (!stats?.characterStats) return null;

    // Funkcje pomocnicze
    const getCharStat = (char) => {
      return stats.characterStats.find(stat => stat.character === char) || { total: 0, correctTotal: 0 };
    };

    const getPercent = (correctTotal, total) => {
      return total > 0 ? Math.round((correctTotal / total) * 100) : 0;
    };

    if (exerciseType === 'syllables' || settings.learningMode === 'liczby') {
      return (
        <table style={styles.statsTable}>
          <thead>
            <tr>
              <th 
                style={styles.statsTableHeader} 
                rowSpan="2" 
                onClick={() => handleSort('character')}
              >
                {settings.learningMode === 'liczby' ? 'Liczba' : 'Sylaba'} {getSortIcon('character')}
              </th>
              <th style={styles.statsTableHeader} colSpan="2">Statystyki</th>
            </tr>
            <tr>
              <th 
                style={styles.statsTableHeader} 
                onClick={() => handleSort('upperViews')}
              >
                <Eye title="Wyświetlenia" /> {sortColumn === 'upperViews' && getSortIcon('upperViews')}
              </th>
              <th 
                style={styles.statsTableHeader} 
                onClick={() => handleSort('upperCorrect')}
              >
                <ThumbsUp title="Poprawne odpowiedzi" /> {sortColumn === 'upperCorrect' && getSortIcon('upperCorrect')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortData(stats.characterStats, sortColumn, sortDirection).map((charStat, index) => (
              <tr key={index}>
                <td style={{...styles.statsTableCell, ...styles.statsTableCellBold}}>
                  {charStat.character}
                </td>
                <td style={styles.statsTableCell}>{charStat.total}</td>
                <td style={styles.statsTableCell}>
                  {charStat.total > 0 ? Math.round((charStat.correctTotal / charStat.total) * 100) : '0'}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      // Litery: wracamy do starej logiki
      // Wyciągamy unikalne duże litery i od razu tworzymy tablicę z pełnymi statystykami
      const uniqueUpperLetters = [...new Set(alphabet)]
        .filter(char => char === char.toUpperCase())
        .map(char => {
          const upper = getCharStat(char);
          const lower = getCharStat(char.toLowerCase());
          return {
            character: char,
            upperTotal: upper.total,
            upperCorrect: upper.correctTotal,
            lowerTotal: lower.total,
            lowerCorrect: lower.correctTotal
          };
        });

      return (
        <table style={styles.statsTable}>
          <thead>
            <tr>
              <th 
                style={styles.statsTableHeader} 
                rowSpan="2" 
                onClick={() => handleSort('character')}
              >
                Litera {getSortIcon('character')}
              </th>
              <th style={styles.statsTableHeader} colSpan="2">Duża</th>
              <th style={styles.statsTableHeader} colSpan="2">Mała</th>
            </tr>
            <tr>
              <th 
                style={styles.statsTableHeader} 
                onClick={() => handleSort('upperViews')}
              >
                <Eye title="Wyświetlenia" /> {sortColumn === 'upperViews' && getSortIcon('upperViews')}
              </th>
              <th 
                style={styles.statsTableHeader} 
                onClick={() => handleSort('upperCorrect')}
              >
                <ThumbsUp title="Poprawne odpowiedzi" /> {sortColumn === 'upperCorrect' && getSortIcon('upperCorrect')}
              </th>
              <th 
                style={styles.statsTableHeader} 
                onClick={() => handleSort('lowerViews')}
              >
                <Eye title="Wyświetlenia" /> {sortColumn === 'lowerViews' && getSortIcon('lowerViews')}
              </th>
              <th 
                style={styles.statsTableHeader} 
                onClick={() => handleSort('lowerCorrect')}
              >
                <ThumbsUp title="Poprawne odpowiedzi" /> {sortColumn === 'lowerCorrect' && getSortIcon('lowerCorrect')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortData(uniqueUpperLetters, sortColumn, sortDirection).map((letterStat, index) => (
              <tr key={index}>
                <td style={{...styles.statsTableCell, ...styles.statsTableCellBold}}>
                  {letterStat.character}
                </td>
                <td style={styles.statsTableCell}>{letterStat.upperTotal}</td>
                <td style={styles.statsTableCell}>{getPercent(letterStat.upperCorrect, letterStat.upperTotal)}%</td>
                <td style={styles.statsTableCell}>{letterStat.lowerTotal}</td>
                <td style={styles.statsTableCell}>{getPercent(letterStat.lowerCorrect, letterStat.lowerTotal)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

  return (
    <>
      <motion.button
        onClick={() => setIsPanelOpen(!isPanelOpen)}
        style={styles.toggleButton}
        whileHover={{ 
          scale: 1.1,
          boxShadow: '-2px 0 10px rgba(0,0,0,0.2)',
        }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.2 }}
        aria-label={isPanelOpen ? "Zamknij panel historii" : "Otwórz panel historii"}
      >
        {isPanelOpen ? <ChevronRight /> : <ChevronLeft />}
      </motion.button>
      <div style={styles.panel}>
        <div className="panel-content" style={{ paddingBottom: '20px' }}>
          <div className="section">
            <div className="section-header" style={styles.sectionHeader} onClick={() => setShowHistory(!showHistory)}>
              <h2>Historia odpowiedzi</h2>
              {showHistory ? <ChevronUp /> : <ChevronDown />}
            </div>
            {showHistory && (
              <div style={styles.historyContent}>
                <ul style={styles.historyList}>
                  {history.map((item, index) => (
                    <li 
                      key={index} 
                      style={{
                        ...styles.historyItem,
                        ...(item.isCorrect ? styles.historyItemCorrect : styles.historyItemIncorrect)
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <span style={styles.historyItemSpan}><strong>Litera/Sylaba:</strong> {item.character}</span>
                        <span style={styles.historyItemSpan}>
                          <strong>Wynik:</strong> {item.isCorrect ? <ThumbsUp /> : <X style={{color: '#dc3545'}} />}
                        </span>
                        <span style={styles.historyItemSpan}><strong>Font:</strong> {item.settings.font}</span>
                        <span style={styles.historyItemSpan}><strong>Kolor:</strong> {colorMap[item.settings.color] || item.settings.color}</span>
                        <span style={styles.historyItemSpan}><strong>Rozmiar:</strong> {sizeMap[item.settings.size] || item.settings.size}</span>
                        <span style={styles.historyItemSpan}><strong>Data:</strong> {formatDate(item.timestamp)}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="section">
            <div className="section-header" style={styles.sectionHeader} onClick={() => setShowStats(!showStats)}>
              <h2>Statystyki</h2>
              {showStats ? <ChevronUp /> : <ChevronDown />}
            </div>
            {showStats && (
              <div style={styles.statsContent}>
                <div style={styles.timeRangeSlider}>
                  <label htmlFor="timeRange">Zakres czasowy:</label>
                  <input
                    type="range"
                    id="timeRange"
                    name="timeRange"
                    min="0"
                    max="4"
                    step="1"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    style={styles.sliderInput}
                  />
                  <div style={styles.sliderLabels}>
                    <span>1D</span>
                    <span>7D</span>
                    <span>30D</span>
                    <span>1Y</span>
                    <span>All</span>
                  </div>
                </div>

                <div style={styles.statsSummary}>
                  <p><Eye /> Wyświetleń: {stats ? stats.totalAttempts : 0}</p>
                  <p><ThumbsUp /> Poprawnych: {stats ? stats.correctResponses : 0} ({stats ? stats.correctPercentage : 0}%)</p>
                </div>

                <div style={styles.statsTable}>
                  {renderStatsTable()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryAndStats;
