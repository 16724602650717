// src/components/Settings.js
import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import { getThemeColors } from '../styles/themes';
import ProfileInfo from './ProfileInfo';
import { motion } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const defaultSettings = {
  learningMode: 'litery',    // 'litery', 'sylaby', 'liczby'
  rodzaj: 'duze',            // dla liczb: '0-9','10-19','20-99','100-999'; dla liter/sylab: 'duze','male','mieszane'
  font: 'Arial',
  color: 'black',
  size: 100,
  order: 'los_popularne',
  theme: 'ksiezniczkowy',
};

function Settings({ 
  settings, 
  setSettings, 
  currentUser, 
  currentProfile,
  setCurrentProfile,
  handleLogout,
  handleSwitchProfile,
  currentCharacter
}) {
  const [localSettings, setLocalSettings] = useState(settings || {});
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [randomFont, setRandomFont] = useState('');
  const availableFonts = ['Arial', 'Times New Roman', 'Courier New', 'Verdana', 'Georgia'];

  useEffect(() => {
    setLocalSettings(settings || {});
  }, [settings]);

  useEffect(() => {
    if (localSettings.font === randomFont && localSettings.learningMode !== 'liczby') {
      const newRandomFont = getRandomFont();
      setRandomFont(newRandomFont);

      const newSettings = { ...localSettings, font: newRandomFont };
      setLocalSettings(newSettings);
      setSettings(newSettings);

      if (currentUser && currentProfile) {
        const settingsRef = doc(db, "users", currentUser.uid, "profiles", currentProfile.id);
        updateDoc(settingsRef, {
          settings: newSettings
        }).catch(error => {
          console.error("Błąd podczas aktualizacji ustawień:", error);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCharacter]);

  const getRandomFont = () => {
    const fonts = availableFonts.filter(font => font !== 'Losowa czcionka');
    return fonts[Math.floor(Math.random() * fonts.length)];
  };

  const basicColors = [
    { name: 'Czarny', value: '#000000' },
    { name: 'Czerwony', value: '#FF0000' },
    { name: 'Niebieski', value: '#0000FF' },
    { name: 'Zielony', value: '#008000' },
    { name: 'Fioletowy', value: '#800080' },
  ];

  const sizeOptions = [
    { name: 'Mały', value: 50, symbol: 'S' },
    { name: 'Większy', value: 100, symbol: 'M' },
    { name: 'Duży', value: 150, symbol: 'L' },
    { name: 'Bardzo Duży', value: 200, symbol: 'XL' },
  ];

  const handleChange = async (e) => {
    const { name, value } = e.target;

    let newValue = value;
    if (name === 'font') {
      if (value === 'random') {
        const randomlySelectedFont = getRandomFont();
        setRandomFont(randomlySelectedFont);
        newValue = randomlySelectedFont;
      } else {
        setRandomFont('');
      }
    }

    const newSettings = { ...localSettings, [name]: newValue };
    setLocalSettings(newSettings);
    setSettings(newSettings);

    if (currentUser && currentProfile) {
      try {
        const settingsRef = doc(db, "users", currentUser.uid, "profiles", currentProfile.id);
        await updateDoc(settingsRef, {
          settings: newSettings
        });
      } catch (error) {
        console.error("Błąd podczas aktualizacji ustawień:", error);
        toast.error("Błąd podczas zapisywania ustawień.");
      }
    }
  };

  const handleColorChange = (colorValue) => {
    const updatedSettings = {
      ...localSettings,
      color: colorValue,
    };
    setLocalSettings(updatedSettings);
    setSettings(updatedSettings);
    autoSave(updatedSettings);
  };

  const handleSizeChange = (sizeValue) => {
    const updatedSettings = {
      ...localSettings,
      size: sizeValue,
    };
    setLocalSettings(updatedSettings);
    setSettings(updatedSettings);
    autoSave(updatedSettings);
  };

  const autoSave = async (updatedSettings) => {
    if (!currentUser || !currentProfile) {
      toast.error("Brak aktywnego profilu.");
      return;
    }

    try {
      const profileDocRef = doc(db, 'users', currentUser.uid, 'profiles', currentProfile.id);
      await updateDoc(profileDocRef, { settings: updatedSettings });
    } catch (error) {
      console.error("Settings: Błąd podczas zapisywania ustawień:", error);
      toast.error("Błąd podczas zapisywania ustawień.");
    }
  };

  const themeColors = getThemeColors(localSettings.theme || 'ksiezniczkowy');

  const styles = {
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      zIndex: 1000,
    },
    toggleButton: {
      position: 'fixed',
      top: '60px',
      left: isPanelOpen ? '315px' : '0px',
      width: '45px',
      height: '30px',
      backgroundColor: themeColors.button,
      border: 'none',
      borderRadius: '0 4px 4px 0',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'left 0.3s, background-color 0.3s',
      zIndex: 1001,
      boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    },
    panel: {
      position: 'fixed',
      top: 0,
      left: isPanelOpen ? '0' : '-300px',
      width: '300px',
      height: '100%',
      background: `linear-gradient(135deg, ${themeColors.panel} 0%, ${themeColors.panel}ee 100%)`,
      boxShadow: '2px 0 20px rgba(0,0,0,0.15)',
      padding: '15px',
      overflowY: 'auto',
      transition: 'left 0.3s, background-color 0.3s',
      zIndex: 1000,
    },
    formGroup: {
      marginBottom: '12px',
      padding: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
    },
    select: {
      width: '100%',
      padding: '6px 10px',
      marginTop: '4px',
      borderRadius: '8px',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      fontSize: '14px',
      transition: 'all 0.3s ease',
    },
    label: {
      display: 'block',
      marginBottom: '3px',
      fontSize: '13px',
      fontWeight: '500',
    },
  };

  // W zależności od trybu nauki, pole "Rodzaj" będzie miało inne opcje
  const renderRodzajOptions = () => {
    if (localSettings.learningMode === 'liczby') {
      return (
        <>
          <option value="0-9">0-9</option>
          <option value="10-19">10-19</option>
          <option value="20-99">20-99</option>
          <option value="100-999">100-999</option>
        </>
      );
    } else {
      // Dla liter i sylab stare opcje 'duze','male','mieszane'
      return (
        <>
          <option value="duze">Duże</option>
          <option value="male">Małe</option>
          <option value="mieszane">Mieszane</option>
        </>
      );
    }
  };

  return (
    <div style={styles.container}>
      <motion.button
        onClick={() => setIsPanelOpen(!isPanelOpen)}
        style={styles.toggleButton}
        whileHover={{ scale: 1.1, boxShadow: '2px 0 10px rgba(0,0,0,0.2)' }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.2 }}
        aria-label={isPanelOpen ? "Zamknij panel ustawień" : "Otwórz panel ustawień"}
      >
        {isPanelOpen ? <FaChevronLeft /> : <FaChevronRight />}
      </motion.button>
      <div style={styles.panel}>
        <ProfileInfo 
          currentProfile={currentProfile}
          handleLogout={handleLogout}
          handleSwitchProfile={handleSwitchProfile}
          theme={localSettings.theme}
        />

        <h2>Ustawienia</h2>
        <div style={styles.formGroup}>
          <label style={styles.label}>Tryb nauki:</label>
          <select 
            name="learningMode" 
            value={localSettings.learningMode || 'litery'} 
            onChange={handleChange} 
            style={styles.select}
          >
            <option value="litery">Litery</option>
            <option value="sylaby">Sylaby</option>
            <option value="liczby">Liczby</option>
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Rodzaj:</label>
          <select 
            name="rodzaj" 
            value={localSettings.rodzaj || 'duze'} 
            onChange={handleChange} 
            style={styles.select}
          >
            {renderRodzajOptions()}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Czcionka:</label>
          <select 
            name="font" 
            value={localSettings.font === randomFont ? 'random' : localSettings.font} 
            onChange={handleChange} 
            style={styles.select}
          >
            <option value="random">Losowa czcionka (akt. {randomFont || 'brak'})</option>
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier New">Courier New</option>
            <option value="Verdana">Verdana</option>
            <option value="Georgia">Georgia</option>
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Kolor:</label>
          <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
            {basicColors.map((color) => (
              <button
                key={color.value}
                onClick={() => handleColorChange(color.value)}
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  backgroundColor: color.value,
                  border: localSettings.color === color.value ? '3px solid #000' : '1px solid #ccc',
                  position: 'relative',
                }}
                title={color.name}
              >
                {localSettings.color === color.value && (
                  <span style={{ color: '#fff', fontSize: '18px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>✔</span>
                )}
              </button>
            ))}
          </div>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Rozmiar:</label>
          <div style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
            {sizeOptions.map((size) => (
              <button
                key={size.value}
                onClick={() => handleSizeChange(size.value)}
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  border: localSettings.size === size.value ? '3px solid #000' : '1px solid #ccc',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#fff',
                }}
                title={size.name}
              >
                <span style={{ fontSize: '16px' }}>{size.symbol}</span>
              </button>
            ))}
          </div>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Kolejność:</label>
          <select name="order" value={localSettings.order || 'los_popularne'} onChange={handleChange} style={styles.select}>
            {localSettings.learningMode === 'liczby' ? (
              <>
                <option value="rosnaca">Rosnąca</option>
                <option value="malejaca">Malejąca</option>
                <option value="losowa">Losowa</option>
              </>
            ) : (
              <>
                <option value="alfabetyczna">Alfabetyczna</option>
                <option value="los_popularne">Los: popularne</option>
                <option value="losowa">Losowa</option>
                <option value="los_cwicz_bledy">Losowy: ćwicz błędy</option>
              </>
            )}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Motyw:</label>
          <select name="theme" value={localSettings.theme || 'ksiezniczkowy'} onChange={handleChange} style={styles.select}>
            <option value="ksiezniczkowy">Księżniczkowy</option>
            <option value="rakietowy">Rakietowy</option>
            <option value="krasnalowy">Krasnalowy</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default Settings;
